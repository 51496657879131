.meme {
    position: relative;
    width: 500px;
    height: 500px;
    overflow: hidden;
    border: 2px black solid;
}

.meme span {
    display: inline-block;
    text-align: center;
    width: 100%;
    align-items: center;
    position: absolute;
    top: 10px;
    font-size: 36px;
    font-weight: bold;
    color: white;
    text-align: center;
    padding: 10px;
    -webkit-text-stroke: 2px black; /* Borde negro de 2px en navegadores WebKit (Safari, Chrome, etc.) */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Sombra con desplazamiento x de 2px, desplazamiento y de 2px y 4px de desenfoque */
    /* Propiedades para limitar a 3 líneas, compatible con navegadores modernos */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word;
}

.meme span:nth-child(2){
    top: 350px;
    -webkit-line-clamp: 2;
}

.meme img {
    width: 100%; /* La imagen ocupará el 100% del ancho del div */
    height: 100%; /* La imagen ocupará el 100% del alto del div */
    object-fit: cover; /* Ajusta la imagen para que cubra todo el contenedor sin distorsionarse */
}